<template>
  <div>
    <el-card class="box-card ccard">
      <div class="cardtitle">绑定关系</div>
      <el-form ref="from" :rules="rules" :model="from" label-width="120px">
        <el-form-item label="用户电话" prop="tel">
          <el-input v-model="from.tel"></el-input>
        </el-form-item>
        <el-form-item label="推广用户电话" prop="referenceTel">
          <el-input v-model="from.referenceTel"></el-input>
        </el-form-item>
        <el-button type="primary" @click="onSubmit('from')">创建关系</el-button>
      </el-form>
    </el-card>
    <el-card class="box-card" style="width:50%">
      <div class="cardtitle">结算分销折扣率</div>
      <el-button type="primary" @click="jiesuan">手动结算</el-button>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      from: {
        tel: "",
        referenceTel: "",
      },
      rules: {
        tel: [{ required: true, message: "请输入用户电话", trigger: "blur" }],
        referenceTel: [
          { required: true, message: "请输入推广用户电话", trigger: "blur" },
        ],
      },
      settleDistribute: "",
    };
  },
  mounted() {},
  methods: {
    jiesuan() {
      this.axios
        .post(this.serveurl.mallserve + "/api/app/Distribute/settle_distribute")
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              type: "success",
              message: "结算成功!",
            });
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
        });
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("确定建立关系吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.axios
                .post(
                  this.serveurl.mallserve +
                    "/api/app/Distribute/rebinding",
                  this.qs.stringify(this.from)
                )
                .then((res) => {
                  if (res.data.code == 1) {
                    this.$message({
                      type: "success",
                      message: "绑定成功!",
                    });
                    this.from.tel = "";
                    this.from.referenceTel = "";
                  } else {
                    this.$message({
                      type: "error",
                      message: res.data.msg,
                    });
                  }
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "取消绑定",
              });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style>
.ccard {
  width: 50%;
  margin-bottom: 20px;
}
.cardtitle {
  text-align: left;
  margin-bottom: 20px;
}
</style>